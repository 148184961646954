<script>
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import OrderStopStatus from "@/components/status/OrderStopStatus.vue";
import { OrderStopState, OrderStopType } from "@/data/order";
import StopChangesPopover from "@/views/trips/components/StopChangesPopover.vue";
import FacilityInfo from "@/views/trips/components/FacilityInfo.vue";
import AlertMessage from "@/components/AlertMessage.vue";

export default {
  name: "OrderStopBlock",
  components: {
    AlertMessage,
    FacilityInfo,
    StopChangesPopover,
    OrderStopStatus,
  },
  mixins: [OrdersMixin],
  props: {
    orderFreights: {
      type: Array,
    },
    routeFreights: {
      type: Array,
    },
    stop: {
      type: Object,
      required: true,
    },
    disabled: Boolean,
  },
  emits: ["change", "delete"],
  computed: {
    freights() {
      const freights = [];
      for (const freight of this.orderFreights) {
        if (this.stop.freights.includes(freight.id)) {
          freights.push({ ...freight, name: this.getFreightName(freight, freight.number, "", false) });
        }
      }
      return freights;
    },
    freightsList() {
      return this.freights.map((f) => f.name).join(", ");
    },
    isUpdated() {
      return this.stop.state === OrderStopState.updated;
    },
    mismatch() {
      if (!this.routeFreights || !this.freights || this.stop.type === OrderStopType.delivery) {
        return null;
      }
      const orderRouteFreights = this.routeFreights.filter((f) => this.freights.map((f) => f.id).includes(f.freightId));
      const orderRouteFreightsQuantity = orderRouteFreights.reduce((acc, freight) => acc + freight.quantity, 0);
      const orderRouteFreightsWeight = orderRouteFreights.reduce((acc, freight) => acc + freight.weight, 0);
      const orderFreightsQuantity = this.freights.reduce((acc, freight) => acc + freight.quantity, 0);
      const orderFreightsWeight = this.freights.reduce((acc, freight) => acc + freight.weight, 0);
      if (orderRouteFreightsQuantity === orderFreightsQuantity && orderRouteFreightsWeight === orderFreightsWeight) {
        return null;
      }
      const chunks = [];
      if (orderRouteFreightsQuantity !== orderFreightsQuantity) {
        const quantityDiff = orderRouteFreightsQuantity - orderFreightsQuantity;
        chunks.push(
          quantityDiff > 0 ? `selected additional ${quantityDiff} pallets` : `not selected ${-quantityDiff} pallets`,
        );
      }
      if (orderRouteFreightsWeight !== orderFreightsWeight) {
        const weightDiff = orderRouteFreightsWeight - orderFreightsWeight;
        chunks.push(weightDiff > 0 ? `selected additional ${weightDiff} lbs` : `not selected ${-weightDiff} lbs`);
      }
      return chunks.join(", ");
    },
  },
};
</script>

<template>
  <div :class="{ 'reload-stop': stop.type === STOP_RELOAD }" class="trip-stops__block">
    <div :class="disabled ? 'bg-red-lighten-5' : 'bg-grey-lighten-4'" class="trip-stops__block-inner pa-4 rounded">
      <div class="mb-6 trip-stops__block-header">
        <div class="d-flex align-center justify-space-between mb-2">
          <div class="d-flex align-center flex-wrap">
            <v-icon v-if="stop.type === STOP_PICK_UP" class="mr-2" color="green" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="stop.type === STOP_RELOAD" class="mr-2" color="orange" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="stop.type === STOP_DELIVERY" class="mr-2" color="red accent-2" size="20">
              mdi-map-marker-radius-outline
            </v-icon>
            <span class="blue-text-grey-darken-1 font-weight-600 mr-3 font-size-12" data-qa="stop-type">
              {{ stop.name }}
            </span>
            <router-link
              v-if="stop.order"
              :to="{ name: 'order-view', params: { id: stop.order.orderId || stop.order.id } }"
              class="font-size-12 font-weight-medium text-primary text-decoration-none"
              target="_blank"
            >
              Order #{{ stop.order.number }}
            </router-link>
            <stop-changes-popover v-if="isUpdated" :stop="stop"></stop-changes-popover>
          </div>
          <div v-if="isUpdated" class="badge bg-warning pa-2 rounded-te rounded-bs font-weight-600">UPDATED</div>
        </div>

        <order-stop-status :stop="stop"></order-stop-status>
      </div>

      <div class="mb-6 trip-stops__block-body">
        <facility-info
          :facility="stop.facility"
          :updated="!!stop.oldFacilityId && !disabled"
          class="mb-5"
        ></facility-info>
        <div v-if="stop.timeType && !stop.isReload" class="d-flex align-center text-grey-darken-1">
          <v-icon class="mr-2" size="20">mdi-clock-outline</v-icon>
          <span class="font-weight-medium" data-qa="stop-time"
            >{{ timeTypesMap[stop.timeType] }}: {{ getStopTime(stop) }}</span
          >
        </div>
      </div>
      <div class="trip-stops__block-footer">
        <div class="d-flex">
          <span class="text-grey-darken-1 mr-2">Freight:</span>
          <span
            :class="disabled ? 'text-error' : 'text-grey-darken-2'"
            class="font-weight-medium"
            data-qa="freight-size"
          >
            {{ freightsList }}
          </span>
        </div>
        <alert-message v-if="mismatch" class="mt-3"> Freight mismatch: {{ mismatch }}</alert-message>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.trip-stops__block-inner {
  min-height: 290px;
}

.reload-stop .trip-stops__block-inner {
  min-height: 610px;
}

.badge {
  margin-top: -16px;
  margin-right: -16px;
}
</style>
