<script>
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import ValidationMixin from "@/mixins/validation.mixin";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import OmniTextarea from "@/components/inputs/Textarea.vue";
import OmniTextField from "@/components/controls/OmniTextField.vue";
import StopDateRange from "@/views/orders/components/StopDateRange.vue";
import OmniDialog from "@/components/OmniDialog.vue";
import { customAlphabet } from "nanoid";
import { OrderStopType } from "@/data/order";

export default {
  name: "EditStopDialog",
  components: { OmniDialog, StopDateRange, OmniTextField, OmniTextarea },
  mixins: [ValidationMixin, DateTimeMixin, OrdersMixin, GoogleMapsMixin],
  props: {
    modelValue: Boolean,
    stop: { type: Object, required: true },
    orderStops: { type: Array, required: true },
    routeFreights: { type: Array, required: true },
    unusedRouteFreights: { type: Array, required: true },
    orderFreights: { type: Array, required: true },
  },
  emits: ["update:modelValue", "changed"],
  data() {
    return {
      stopRouteFreights: [],
      stopData: {},
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    facilityAddress() {
      const chunks = [];
      if (this.stop.facility.addressLine) {
        chunks.push(this.stop.facility.addressLine);
      }
      if (this.stop.facility.address) {
        chunks.push(this.stop.facility.address);
      }
      return chunks.join(", ");
    },
    orderFreightsWithName() {
      return this.orderFreights
        .filter((f) => this.stopRouteFreights.map((srf) => srf.freightId).includes(f.id))
        .map((f) => ({
          ...f,
          name: this.getFreightName(f, f.number, "", false),
        }));
    },
    isPickup() {
      return this.stop.type === OrderStopType.pickup;
    },
    isDelivery() {
      return this.stop.type === OrderStopType.delivery;
    },
    quantityLabel() {
      return this.isPickup ? "Planned quantity" : "Quantity";
    },
    weightLabel() {
      return this.isPickup ? "Planned total weight" : "Total weight";
    },
  },
  watch: {
    modelValue(value) {
      if (!value) {
        this.stopRouteFreights = [];
        this.stopData = {};
      } else {
        this.stopRouteFreights = this.stop.freights.map((f) => ({ ...this.routeFreights.find((rf) => rf.id === f) }));
        this.stopData = {
          timeType: this.stop.timeType,
          timezone: this.stop.timezone,
          timeFrom: this.stop.timeFrom,
          timeTo: this.stop.timeTo,
          time2Type: this.stop.time2Type,
          time2From: this.stop.time2From,
          time2To: this.stop.time2To,
        };
      }
    },
  },
  methods: {
    getOrderStopsByFreight(freight) {
      return this.orderStops.filter((os) => os.freights.includes(freight?.id));
    },
    getOrderByFreight(freight) {
      return this.getOrderStopsByFreight(freight)[0].order;
    },
    getFreightPoints(freight) {
      const orderStops = this.getOrderStopsByFreight(freight);
      return orderStops.map((os) => ({ ...os.facility, fullAddress: os.facility.address }));
    },
    getRouteFreightsForOrderFreight(orderFreight) {
      return this.routeFreights
        .filter((rf) => rf.freightId === orderFreight.id)
        .map((rf, i) => {
          rf.number = i + 1;
          return rf;
        });
    },
    getStopRouteFreightsForOrderFreight(orderFreight) {
      return this.stopRouteFreights
        .filter((rf) => rf.freightId === orderFreight.id)
        .map((rf, i) => {
          rf.number = i + 1;
          return rf;
        });
    },
    addFreight(orderFreight) {
      this.stopRouteFreights.push({
        id: this.generateId(),
        freightId: orderFreight.id,
        quantity: 1,
        weight: 1,
        stackable: orderFreight.stackable,
        type: orderFreight.type,
        plannedWeight: orderFreight.weight,
        plannedQuantity: orderFreight.quantity,
      });
    },
    isSelected(routeFreight) {
      return this.stopRouteFreights.map((rf) => rf.id).includes(routeFreight.id);
    },
    selectFreight(routeFreight, value) {
      if (value) {
        this.stopRouteFreights.push(routeFreight);
      } else {
        this.stopRouteFreights = this.stopRouteFreights.filter((rf) => rf.id !== routeFreight.id);
      }
    },
    generateId() {
      const nanoid = customAlphabet("1234567890abcdef", 16);
      return nanoid();
    },
    save() {
      const stop = { ...this.stop, ...this.stopData, freights: this.stopRouteFreights.map((f) => f.id) };
      this.$emit("changed", stop, this.stopRouteFreights);
      this.showDialog = false;
    },
  },
};
</script>

<template>
  <omni-dialog
    v-model="showDialog"
    :title="'Edit route stop - ' + stop.name"
    primary-action-label="Save"
    width="830"
    @primary-action:click="save"
  >
    <div>
      <div class="mb-4">
        <div class="text-grey-darken-1 mb-1">Facility</div>
        <div class="text-grey-darken-3 text-uppercase font-size-16">{{ stop.facility.name }}</div>
      </div>
      <div class="bg-grey-lighten-5 pa-3 rounded mb-6">
        <div class="text-grey-darken-3 font-size-16 mb-2">{{ facilityAddress }}</div>
        <div class="d-flex align-center mb-6">
          <v-icon class="mr-2" color="green" size="16" small> mdi-map-marker-radius-outline</v-icon>
          <a
            :href="getPlaceUrl(stop.facility.addressCoordinates)"
            class="text-green caption text-decoration-underline"
            target="_blank"
          >
            Lat: {{ stop.facility.addressCoordinates.lat }} Lon: {{ stop.facility.addressCoordinates.lon }}
          </a>
        </div>
        <omni-textarea label="Facility note" readonly></omni-textarea>
      </div>
      <div class="mb-6">
        <div class="text-grey-darken-3 font-size-16 font-weight-500">Freight(s) from order:</div>
        <div v-for="orderFreight in orderFreightsWithName" :key="orderFreight.id" class="mt-4">
          <div class="bg-indigo-lighten-5 pa-4">
            <div class="d-flex align-center mb-2">
              <div class="text-grey-darken-3 font-weight-500 font-size-16">{{ orderFreight.name }}</div>
              <router-link
                :to="{ name: 'order-view', params: { id: getOrderByFreight(orderFreight)?.id } }"
                class="text-decoration-none text-primary font-weight-500 ml-4"
                target="_blank"
              >
                Order #{{ getOrderByFreight(orderFreight)?.number }}
              </router-link>
            </div>
            <div class="text-grey-darken-1 d-flex align-center">
              <template v-for="(point, i) in getFreightPoints(orderFreight)" :key="point.id">
                {{ point.fullAddress }}
                <v-icon
                  v-if="i < getFreightPoints(orderFreight).length - 1"
                  class="mx-1"
                  icon="mdi-arrow-right"
                  size="14"
                ></v-icon>
              </template>
            </div>
          </div>
          <div v-if="isPickup" class="bg-grey-lighten-5 pa-4 rounded-b freight-border">
            <div class="text-grey-darken-2 mb-4">Add route freight for order freight:</div>
            <template v-for="routeFreight in getStopRouteFreightsForOrderFreight(orderFreight)" :key="routeFreight.id">
              <div class="text-grey-darken-3 font-weight-500 mb-4">Route freight #{{ routeFreight.number }}:</div>
              <v-row class="mb-3">
                <v-col>
                  <omni-text-field v-model="routeFreight.quantity" :label="quantityLabel" required></omni-text-field>
                </v-col>
                <v-col>
                  <omni-text-field
                    v-model="routeFreight.weight"
                    :label="weightLabel"
                    required
                    suffix="lbs"
                  ></omni-text-field>
                </v-col>
              </v-row>
            </template>
            <v-btn class="px-2" density="compact" variant="text" @click="addFreight(orderFreight)">
              <div class="d-flex align-center text-primary text-uppercase font-size-11 font-weight-600">
                <v-icon class="mr-2" icon="mdi-plus"></v-icon>
                Add route freight
              </div>
            </v-btn>
          </div>
          <div v-if="isDelivery" class="bg-grey-lighten-5 pa-4 rounded-b freight-border">
            <div class="text-grey-darken-2 mb-4">Select route freight(s) to be delivered to this stop:</div>
            <div v-for="routeFreight in getRouteFreightsForOrderFreight(orderFreight)" :key="routeFreight.id">
              <v-checkbox
                :label="getFreightName(routeFreight, routeFreight.number, '', false)"
                :model-value="isSelected(routeFreight)"
                color="primary"
                density="compact"
                hide-details
                @update:model-value="(val) => selectFreight(routeFreight, val)"
              ></v-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex align-center text-grey-darken-1 font-weight-500 mb-4">
          <v-icon class="mr-2" icon="mdi-clock-outline" size="16"></v-icon>
          Order stop time: {{ timeTypesMap[stop.timeType] }}: {{ getStopTime(stop) }}
        </div>
        <stop-date-range v-model="stopData" multiline></stop-date-range>
      </div>
    </div>
  </omni-dialog>
</template>

<style lang="scss" scoped>
.freight-border {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
</style>
